<template>
  <v-app>
    <v-system-bar>
      <v-icon icon="mdi-wifi-strength-4"></v-icon>
      <v-icon icon="mdi-signal" class="ms-2"></v-icon>
      <v-icon icon="mdi-battery" class="ms-2"></v-icon>
      <span class="ms-2">{{ hhmm }}</span>
    </v-system-bar>

    <v-app-bar color="primary" dark app clipped-left v-if="store.getters.getIsLogin">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title to="/">{{ store.getters.getSelected.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn text v-bind="props">
              {{ store.getters.getNickname }}
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item prepend-icon="mdi-account-circle" title="プロファイル" to="/profile"></v-list-item>
            <v-list-item prepend-icon="mdi-logout" title="ログオフ" @click="logoff"></v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view :key="componentKey" />
    </v-main>

    <div class="d-sm-none">
      <v-footer app color="primary" v-if="store.getters.getIsLogin">
        <v-spacer></v-spacer>

        <v-btn v-for="item in activeItems" :key="item.id" variant="flat" height="40" class="text-caption" stacked
          :prepend-icon="item.icon" :to="item.path">
          {{ item.label }}
        </v-btn>

        <v-spacer></v-spacer>
      </v-footer>
    </div>

    <v-navigation-drawer v-if="store.getters.getIsLogin" v-model="drawer" width="300">
      <TreeView :count="componentKey" @set:newCount="setNewCount" />
    </v-navigation-drawer>
  </v-app>
</template>

<script setup>
// ナビゲーションバーのレンダリング初期化
import { ref, reactive, onMounted, onBeforeUnmount, computed } from "vue";
const drawer = ref(null);
drawer.value = false;

const componentKey = ref(0);
import TreeView from "@/components/TreeView.vue";

const items = reactive(
  [
    { id: 6, activate: false, label: '概要', icon: "mdi-view-dashboard", path: "/dash" },
    { id: 5, activate: false, label: '環境衛生', icon: 'mdi-air-filter', path: '/env' },
    { id: 7, activate: false, label: '設備保全', icon: 'mdi-water-boiler', path: '/cons' },
    { id: 8, activate: false, label: '省エネ', icon: 'mdi-power-standby', path: '/energy' },
  ]
);

// ルータの初期化
import { useRouter } from "vue-router";
const router = useRouter();

// ストアの初期化
import { useStore } from "vuex";
const store = useStore();

// メニューの有効化
for (var id of store.getters.getSelected.license) {
  for (var item of items) {
    if (item.id == id) item.activate = true;
  }
}

const activeItems = computed(() => items.filter(function (item) {
  return item.activate
}));

// ログオフ処理
const logoff = () => {
  router.push("/");
  store.commit("setIsLogin", false);
  // alert("Login:" + store.getters.getIsLogin);
};

// インターバルタイマー開始
import { useDate } from "vuetify";
const date = useDate();
const hhmm = ref(1);
var interval = -1;

onMounted(() => {
  interval = setInterval(() => {
    hhmm.value = date.format(new Date(), "fullTime24h");
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

const setNewCount = (newCount) => {
  // console.log("old:" + componentKey.value + " new:" + newCount);
  componentKey.value = newCount;
  drawer.value = false;
};
</script>
