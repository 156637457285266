// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { VCalendar } from 'vuetify/labs/VCalendar';

// Vuetify
import { createVuetify } from 'vuetify'
import { md3 } from 'vuetify/blueprints'

export default createVuetify(
  {
    blueprint: md3,
    components: { VCalendar, },
  }
)


