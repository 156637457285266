import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 画面コンポーネントの初期化
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
loadFonts();

// バリデーションの初期化
import { defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import { all } from "@vee-validate/rules";

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});

configure({
  generateMessage: localize({
    ja,
  }),
});

localize("ja");

import SetInterval from '@/plugins/SetInterval';

createApp(App).use(router).use(store).use(vuetify).use(SetInterval).use(document.documentElement.setAttribute('lang', 'ja')).mount("#app");
