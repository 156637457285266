import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate' //vuex-persistedstateをインストールする必要があります

export default createStore({
  state: {
    base_url: 'https://itec-iot.server-on.net/api/',
    isLogin: false, //ログイン状態
    profile: {
      user_id: 0,
      mail_address: '',
      nickname: '',
      role: 0,
    },
    authToken: '',  //トークン
    domain: [{ id: 0, title: '担当はありません', type: 4 }],
    selected: { id: 0, title: '拠点選択', type: 4, license: [] },
    role: [], //権限
    imageOnMap: {
      center: [35.6895, 139.6917], // 初期中心座標（東京）
      bounds: {}
    }
  },
  mutations: {
    setProfile(state, value) {
      state.profile = value
    },
    setAuthToken(state, value) {
      state.authToken = value
    },
    setIsLogin(state, value) {
      state.isLogin = value
    },
    setSelected(state, value) {
      state.selected = value
    },
    setDomain(state, value) {
      state.domain = value
    },
    setRole(state, value) {
      state.profile.role = value
    },
    setMapCenter(state, value) {
      state.imageOnMap.center = value
    },
    setMapBounds(state, value) {
      state.imageOnMap.bounds = value
    },
  },
  actions: {
    setProfile: function (commit, value) {
      commit('setProfile', value)
    },
    setAuthToken: function (commit, value) {
      commit('setAuthToken', value)
    },
    setIsLogin: function (commit, value) {
      commit('setIsLogin', value)
    },
    setSelected: function (commit, value) {
      commit('setSelected', value)
    },
    setDomain: function (commit, value) {
      commit('setDomain', value)
    },
    setRole: function (commit, value) {
      commit('setRole', value)
    },
    setMapCenter: function (commit, value) {
      commit('setMapCenter', value)
    },
    setMapBounds: function (commit, value) {
      commit('setMapBounds', value)
    },
  },
  getters: {
    getProfile(state) {
      return state.profile
    },
    getNickname(state) {
      return state.profile.nickname
    },
    getUserId(state) {
      return state.profile.user_id
    },
    getMailAddress(state) {
      return state.profile.mail_address
    },
    getAuthToken(state) {
      return state.authToken
    },
    getIsLogin(state) {
      return state.isLogin
    },
    getSelected(state) {
      return state.selected
    },
    getDomain(state) {
      return state.domain
    },
    getRole(state) {
      return state.profile.role
    },
    getBaseURL(state) {
      return state.base_url
    },
    getMapCenter(state) {
      return state.imageOnMap.center
    },
    getMapBounds(state) {
      return state.imageOnMap.bounds
    },
  },

  plugins: [createPersistedState(
    {
      storage: window.sessionStorage
    }
  )]

})