import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/dash',
    name: 'Dashboard',
    component: () => import('@/views/DashboardView.vue')
  },
  {
    path: '/cons',
    name: 'Conservation',
    component: () => import('@/views/ConservationView.vue')
  },
  {
    path: '/env',
    name: 'Environment',
    component: () => import('@/views/EnvironmentView.vue')
  },
  {
    path: '/energy',
    name: 'EnergyManagementView',
    component: () => import('@/views/EnergyManagementView.vue')
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import('@/views/ProfileView.vue')
  },
  { // 未定義のパスは４０４画面へ
    path: '/:catchAll(.*)',
    component: () => import('@/views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 未ログインはログイン画面へ
router.beforeEach((to, from, next) => {
  if ( store.getters.getIsLogin || to.name === 'login') 
   next() ; 
  else { 
    next({ name: 'login' }) ;
    alert("ログインしてください");
  }
})

export default router
